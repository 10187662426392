
import { AnalyticsTypes } from "@/store/modules/analytics/analytics.types";
import { FilterType, FilterValue } from "@/types/types";
import { Component, Ref, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Analytics = namespace("Analytics");

@Component({
  components: {
    TicketStats: () => import("@/components/dashboard/TicketStats.vue"),
    MyTicketStats: () => import("@/components/dashboard/MyTicketStats.vue"),
    WeeklyStats: () => import("@/components/dashboard/WeeklyStats.vue"),
    CategoryStats: () => import("@/components/dashboard/CategoryStats.vue"),
    TopTickets: () => import("@/components/ticket/TopTickets.vue"),
  },
})
export default class DashboardView extends Vue {
  @Analytics.Getter(AnalyticsTypes.CURRENT_DASHBOARD_FILTER)
  public currentDashboardFilter!: FilterType;

  @Analytics.State(AnalyticsTypes.DASHBOARD_FILTER_TYPES)
  public filterTypes!: FilterType[];

  public filterValue: FilterType = { title: "Weekly", value: "Week" };

  @Ref("categoryStats") public categoryStatsRef!: DashboardComponent;
  @Ref("myTicketStats") public myTicketStatsRef!: DashboardComponent;
  @Ref("ticketStats") public ticketStatsRef!: DashboardComponent;
  @Ref("weeklyStats") public weeklyStatsRef!: DashboardComponent;

  filterAnalytics(filterValue: FilterType, forced = false): void {
    this.categoryStatsRef.filterChange({ filterValue, forced });
    this.myTicketStatsRef.filterChange({ filterValue, forced });
    this.ticketStatsRef.filterChange({ filterValue, forced });
    this.weeklyStatsRef.filterChange({ filterValue, forced });
  }

  mounted(): void {
    this.filterValue = this.currentDashboardFilter;
  }
}

export type DashboardComponent = {
  filterChange: (value: FilterValue) => void;
};
